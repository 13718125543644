/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { ReactElement, PropsWithChildren, useEffect } from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import { TelemetryService } from "../../services/telemetry/TelemetryService";
import favicon from "../../assets/media/favicon.svg";

interface Helmet {
    title?: string;
    description?: string;
    lang?: string;
    meta?: Meta[];
}

interface Meta {
    name: string;
    content: string;
}

function Helmet(props: PropsWithChildren<Helmet>): ReactElement {
    const telemetryService = useDependency(TelemetryService);
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    useEffect(() => {
        if (props.title) telemetryService.pageView(props.title);
    }, []);

    const metaDescription = props.description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata?.title ?? "dccToolbox";

    return (
        <ReactHelmet
            htmlAttributes={{ lang: props.lang }}
            title={props.title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
            meta={[
                {
                    name: "description",
                    content: metaDescription,
                },
                {
                    property: "og:title",
                    content: props.title,
                },
                {
                    property: "og:description",
                    content: metaDescription,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary",
                },
                {
                    name: "twitter:creator",
                    content: site.siteMetadata?.author || "",
                },
                {
                    name: "twitter:title",
                    content: props.title,
                },
                {
                    name: "twitter:description",
                    content: metaDescription,
                },
                {
                    name: "twitter:dnt",
                    content: "on",
                },
            ]}
            link={[
                {
                    rel: "icon",
                    href: favicon,
                },
            ]}>
            {props.children}
        </ReactHelmet>
    );
}

export default Helmet;
